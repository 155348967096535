import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './Location.css';

function Location() {
  return (
    <div className="location">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.5462672639947!2d72.81871277498688!3d19.43200088184725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a96e99222197%3A0x5eff0241bc784d42!2sShree%20Guru%20Govind%20High%20School!5e0!3m2!1sen!2sin!4v1704872256901!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Location 1"
      ></iframe>
      <div className='address-container'>
        <div className='address address1'>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p>
          <a href="https://www.google.com/maps/place/Thakur+School+and+Junior+College/@19.4378173,72.813493,16z/data=!4m6!3m5!1s0x3be7a96fb66c2625:0xd5f197132118953f!8m2!3d19.4341727!4d72.8186954!16s%2Fg%2F11xds2scx?hl=en&entry=ttu" target="_blank" rel="noopener noreferrer">

            Thakur Vidya Mandir High School,<br />
            Pragati Nagar, Ostwal Nagari,<br/>
            Nallasopara (E),401209</a></p>
        </div>
        <div className='address address2'>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p>
          <a href="https://www.google.com/maps/place/8+Limbs+Yoga+Classes/@19.408633,72.8255873,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7a93c876cf81b:0x173ce4b2f5c06b75!8m2!3d19.408633!4d72.8255873!16s%2Fg%2F11qn08k54w?hl=en&entry=ttu" target="_blank" rel="noopener noreferrer">
            8 Limbs Yoga Studio,<br />
            Yashwant Park Vasai,
            Near Bank Of India, <br/>
            Nallasopara (E), 401209</a></p>
        </div>
        <div className='address address3'>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p>
          <a href="https://www.google.com/maps/place/FLICKERING+DANCE+FACTORY+./@19.4031093,72.833944,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7a96980a2b965:0x896b3ff77dc09569!8m2!3d19.4031093!4d72.8365189!16s%2Fg%2F11b8c2y45p?hl=en&entry=ttu" target="_blank" rel="noopener noreferrer"> 
            Flickering Dance Factory,<br />
            Shop no: 12, Dattatray Tower,<br />
            Evershine City, Opp Dreamland Hotel,<br />
            Near Nageshwar Mandir, 
            Phase-2, Vasai East, 401208
            </a>
          </p>
        </div>
        <div className='address address3'>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p>
          <a href="https://www.google.com/maps/place/Shree+Guru+Govind+High+School/@19.4320009,72.8187128,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7a96e99222197:0x5eff0241bc784d42!8m2!3d19.4320009!4d72.8212877!16s%2Fg%2F1pyql3kln?hl=en&entry=ttu" target="_blank" rel="noopener noreferrer"> 
            Shree Guru Govind High School,<br/>
            Jijai Nagar, Ostwal Nagri,<br/>
            Nallasopara East,401209
            </a>
          </p>
        </div>
        <div className='address address4'>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p>
          <a href="https://www.google.com/maps/place/Lokmanya+High+School+%26+Junior+College/@19.4271326,72.8177523,17z/data=!4m10!1m2!2m1!1sLokmanya+High+School+%26+Junior+College,+++++++++++++Vijay+Nagar,+Nallasopara+(E)!3m6!1s0x3be7a96a2efa5a2f:0xea46491bb51fb4b7!8m2!3d19.4271326!4d72.8225159!15sCk9Mb2ttYW55YSBIaWdoIFNjaG9vbCAmIEp1bmlvciBDb2xsZWdlLCAgICAgICAgICAgICBWaWpheSBOYWdhciwgTmFsbGFzb3BhcmEgKEUpWkEiP2xva21hbnlhIGhpZ2ggc2Nob29sICYganVuaW9yIGNvbGxlZ2UgdmlqYXkgbmFnYXIgbmFsbGFzb3BhcmEgZZIBBnNjaG9vbJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VOSGRFMVlSVTFSRUFF4AEA!16s%2Fg%2F11cfj62j9?hl=en&entry=ttu" target="_blank" rel="noopener noreferrer"> 
            Lokmanya High School & Junior College,<br/>
            Vijay Nagar, Nallasopara (E),401209</a></p>
        </div>
      </div>
    </div>
  );
}

export default Location;

import React, { useState } from 'react';
import '../Components/AboutUs.css';
import aboutus from '../images/png/aboutus.jpg';
import background from '../images/logo.PNG'; 

function AboutUs() {
  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(true);
  };

  return (
    <div id='aboutus' className="aboutus-container">
      <div className="background-image" style={{ backgroundImage: `url(${background})` }}></div>
      <div className='aboutus-text'>
        <h1>ABOUT US</h1>
        <p>
          Welcome to Karate Yodha's, a haven where the essence of discipline, strength, and mindfulness converges within the realm of martial arts. Founded with a visionary commitment to disseminate the profound teachings of Shotokan Karate, integrate elements of yoga, and amplify physical prowess through strength training, Karate Yodha's is more than a mere school—it's a transformative journey towards holistic self-improvement. Rooted in the principles of Shotokan Karate, our philosophy extends beyond physical training, instilling values of discipline, respect, and perseverance. Through a unique fusion of Shotokan Karate, yoga, and strength training, our goal is to shape individuals not just into adept martial artists but into well-rounded, balanced beings. 
          {showMore ? (
            <>
              Our curriculum caters to all skill levels, fostering progression from fundamentals to competitive expertise, including district, state, national, and international championships. Since our inception in March 2022, Karate Yodha's has expanded its reach, establishing branches in various schools and studios, emphasizing the integration of diverse art forms. Founded with a singular vision by our dedicated leader, Karate Yodha's aims to make self-defense and martial arts accessible to all, regardless of age or background. Join us on this transformative journey at Karate Yodha's, where we don't just shape martial artists but cultivate individuals embodying strength, resilience, and the spirit of Shotokan Karate in every facet of life. Together, let's embark on a journey of self-discovery, empowerment, and excellence.
            </>
          ) : (
            <>
              <span onClick={handleReadMoreClick} className="read-more-button"> Read More </span>
            </>
          )}
        </p>
      </div>
      <div className='aboutus-image'>
        <img src={aboutus} alt='aboutus'/>
      </div>
    </div>
  );
}

export default AboutUs;

import React from 'react';
import './SocialIcon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function SocialIcon() {
  return (
    <div className="social-icon">
      <a href="https://www.facebook.com/profile.php?id=61556039204072">
        <FontAwesomeIcon icon={faFacebook} className="icon" />
      </a>
      <a href="https://www.youtube.com/@_karateyodhas_">
        <FontAwesomeIcon icon={faYoutube} className="icon" />
      </a>
      <a href="https://www.instagram.com/_karateyodhas_?igsh=MWhtbHZoZDRnYTJ6Zw==">
        <FontAwesomeIcon icon={faInstagram} className="icon" />
      </a>
      <a href="https://wa.me/+917499671115">
        <FontAwesomeIcon icon={faWhatsapp} className="icon" />
      </a>

    </div>
  );
}

export default SocialIcon;

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import mailIcon from '../images/png/mail.png'; 

const StyledContactForm = styled.div`
  color: white;
  font-size: 1rem;
  position: relative; /* Ensure relative positioning for absolute child */
`;

const ContactHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ContactUsText = styled.h1`
  margin-left: 230px;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 5px; /* Adjust as needed */
  }
`;

const MailIcon = styled.img`
  width: 60px; /* Default width */
  height: 40px; /* Default height */

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0; /* Adjust as needed */
  }
`;

const SubmitMessage = styled.div`
  background-color: #32cd32;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 10px; /* Adjust top position */
  right: 10px; /* Adjust right position */
  z-index: 999; /* Ensure it's above other content */
  display: flex;
  align-items: center;
`;

const SuccessTick = styled.span`
  margin-right: 5px;
`;

const ContactFormWrapper = styled.div`
  width: 100%; /* Adjust width as needed */
  max-width: 400px; /* Example maximum width */
  margin: 0 auto; /* Center the form horizontally */
`;

const Contact = () => {
  const form = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // Added state for phone number
  const [submitMessage, setSubmitMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_4vh10ci', 'template_b4rw8xn', form.current, 'uoqCBX1sQCRyk4M5u')
      .then(
        (result) => {
          console.log(result.text);
          console.log('message sent');
          setSubmitMessage('Submitted!');
          // Clear the form fields
          setName('');
          setEmail('');
          setMessage('');
          setPhoneNumber(''); // Clear phone number field
          setTimeout(() => {
            setSubmitMessage('');
          }, 5000);
        },

        (error) => {
          console.log(error.text);
          setSubmitMessage('Error submitting the form. Please try again.');
        }
      );
  };

  return (
    <StyledContactForm>
      <div id='contact'>
        <ContactHeader>
          <ContactUsText>CONTACT US</ContactUsText>
          <MailIcon src={mailIcon} alt="Mail Icon" />
        </ContactHeader>
        <ContactFormWrapper>
          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type='text' name='user_name' value={name} onChange={(e) => setName(e.target.value)} required />
            <label>Email</label>
            <input type='email' name='user_email' value={email} onChange={(e) => setEmail(e.target.value)} required />
            <label>Phone</label> 
            <input type='tel' name='user_phone' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
            <label>Message</label>
            <textarea name='message' value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
            <input type='submit' value='Send' />
          </form>
          {submitMessage && (
            <SubmitMessage>
              <SuccessTick>✓</SuccessTick> {submitMessage}
            </SubmitMessage>
          )}
        </ContactFormWrapper>
      </div>
      <div className='copyright-page'>
        <div className='content'>
          <p>&copy; 2024 KarateYodhas.com All Rights Reserved. | Designed & Developed By Raghvendra Pratap Nishad</p>
        </div>
      </div>
    </StyledContactForm>
  );
};

export default Contact;

import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div id='main' className='center-container'>
      <div className='header-heading'>
        <h2>Hello Yodha's</h2>
        <h1><span>BUILD</span> WITH US</h1>
        <p className='details'> Build & Defence Your Body With Professional Touch </p>
        <div className='header-btns'>
          <Link to='/contact' className='header-btn'> JOIN US</Link>
        </div>
      </div>
    </div>
  )
}

export default Header;

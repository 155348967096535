import React from 'react';
import { BrowserRouter as Router, Route, Routes,  } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import AboutUs from './Components/AboutUs';
import Achievement from './Components/Achievement';
import Location from './Components/Location';
import Contact from './Components/Contact';
import SocialIcon from './Components/SocialIcon';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/achievements" element={<Achievement />} />
          <Route path="/locations" element={<Location />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <SocialIcon />
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <Header />
      <AboutUs />
      <Achievement />
      <Location />
      <Contact />
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import '../Components/Achievement.css';
import achievement from '../images/achievement.png';
import achievement1 from '../images/achievement1.jpg';
import achievement2 from '../images/achievement2.jpg';
import achievement4 from '../images/achievement4.jpg';
import achievement5 from '../images/achievement5.jpg';
import achievement6 from '../images/achievement6.jpg';
import achievement7 from '../images/achievement7.jpg';
import achievement8 from '../images/achievement8.jpg';
import achievement9 from '../images/achievement9.jpg';
import achievement10 from '../images/achievement10.jpg';
import achievement11 from '../images/achievement11.jpg';
import achievement12 from '../images/achievement12.jpg';
import achievement13 from '../images/achievement13.jpg';
import achievement14 from '../images/achievement14.jpg';
import achievement15 from '../images/achievement15.jpg';
import achievement16 from '../images/achievement16.jpg';
import achievement17 from '../images/achievement17.jpg';
import achievement18 from '../images/achievement18.jpg';
import achievement19 from '../images/achievement19.jpg';
import achievement20 from '../images/achievement20.jpg';
import achievement21 from '../images/achievement21.jpg';
import achievement22 from '../images/achievement22.jpg';
import achievement23 from '../images/achievement23.jpg';
import achievement24 from '../images/achievement24.jpg';
import achievement25 from '../images/achievement25.jpg';
import achievement26 from '../images/achievement26.jpg';
import achievement27 from '../images/achievement27.jpg';
import achievement28 from '../images/achievement28.jpg';
import certificate1 from '../images/certificates/1.png';
import certificate2 from '../images/certificates/2.png';
import certificate3 from '../images/certificates/3.png';
import certificate4 from '../images/certificates/4.jpg';
import certificate5 from '../images/certificates/5.jpg';
import certificate6 from '../images/certificates/6.jpg';
import certificate7 from '../images/certificates/7.jpg';
import certificate8 from '../images/certificates/8.jpg';
import certificate9 from '../images/certificates/9.jpg';
import certificate10 from '../images/certificates/10.jpg';
function Achievement() {
  const [certModalOpen, setCertModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [certificateImages] = useState([
    certificate1,
    certificate2,
    certificate3,
    certificate4,
    certificate5,
    certificate6,
    certificate7,
    certificate8,
    certificate9,
    certificate10
  ]);

  const images = [achievement, achievement1, achievement2, achievement4, achievement5,achievement6, achievement7,achievement8,achievement9,achievement10,achievement11,achievement12,achievement13,achievement14,achievement15,achievement16,achievement17,achievement18,achievement19,achievement20,achievement21,achievement22,achievement23,achievement24,achievement25,achievement26,achievement27,achievement28];

  const openCertModal = () => {
    setCertModalOpen(true);
  };

  const closeCertModal = () => {
    setCertModalOpen(false);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div id="achievement">
      <h1>ACHIEVEMENTS</h1>
      <div className="achievement-carousel">
        {images.map((image, index) => (
          <div
            key={index}
            className={`achievement-card ${index === currentImageIndex ? 'active' : ''}`}
            style={{
              transform: `rotateY(${(index - currentImageIndex) * 90}deg) translateZ(180px)`,
              zIndex: index === currentImageIndex ? 1 : 0
            }}
            onClick={() => setCurrentImageIndex(index)}
          >
            <img src={image} alt={`achievement${index}`} />
          </div>
        ))}
      </div>
      <div className="slider-buttons">
        <button onClick={handlePrevImage} style={{ marginRight: '10px' }}>
          {'<'}
        </button>
        <button onClick={handleNextImage}>{'>'}</button>
      </div>
      <div className="achievement-text">
        <p>
          Gautam Yadav, a highly accomplished martial artist, has earned widespread recognition through his outstanding
          achievements in the realm of karate. With an impressive track record, he has secured victories at the
          district, state, national, and even international levels. Demonstrating exceptional skill and dedication,
          Gautam Yadav holds the esteemed rank of 3rd Dan black belt in karate, a testament to his mastery of the
          martial art. His success across various competitions reflects not only his technical prowess but also his
          unwavering commitment to excellence in the discipline of karate.
        </p>
        <button onClick={openCertModal}>Certificates</button>

        {certModalOpen && (
          <div className="modal-overlay" onClick={closeCertModal}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <span className="close" onClick={closeCertModal}>
                &times;
              </span>
              {certificateImages.map((certImage, index) => (
                <img key={index} src={certImage} alt={`certificate${index}`} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Achievement;